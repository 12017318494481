
import { defineComponent, onMounted, reactive } from "vue";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useToast } from "vue-toastification";
export default defineComponent({
  name: "CartProduct",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listData: [],
      listCompany: [],
      listSekolah: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      dir: 1,
      sorting: "sort=name&dir=1",
      companyId: "",
      role: "",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    // this.getData(this.paging.size, this.paging.page);
    this.getListSekolah();
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.cekRoleAkses();
    // this.getListCompany();
    // setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    sortnfc() {
      if (this.dir == 1) {
        this.sorting = "sort=nfc_id&dir=" + this.dir;
        this.dir = -1;
      } else {
        this.sorting = "sort=nfc_id&dir=" + this.dir;
        this.dir = 1;
      }
      this.getData(this.paging.size, this.paging.page);
    },
    cekRoleAkses() {},
    getData(size, page) {
      ApiService.getWithoutSlug(
        // "crmv2/order/cart/admin/62cfcd8c3558d83215158df4/all?page=0&limit=10&sort=createTime&dir=-1"
        "crmv2/order/cart/admin/" +
          this.companyId +
          "/all?" +
          "page=" +
          page +
          "&limit=" +
          size +
          "&sort=createTime&dir=-1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    getListSekolah() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school?status=closing,live"
      ).then(({ data }) => {
        this.listSekolah = data;
        localStorage.setItem("listSchool", JSON.stringify(data));
      });
    },
    getListCompany() {
      ApiService.getWithoutSlug("crmv2/main_card/util/combo_company").then(
        ({ data }) => {
          this.listCompany = data;
          localStorage.setItem("listCompany", JSON.stringify(data));
        }
      );
    },
    pilihCompany(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.companyId = event;
      } else {
        this.companyId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("companyId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    clickview(val) {},

    autoTab(input, len, e, index1) {
      console.log(input);
      console.log(len);
      console.log(e);
      console.log(index1);
      // const index = this.findIndex(e.target);
      // const index = this.findIndex(e.target);
      // console.log(index);
      this.moveFocus(index1 + 1);
      // if (input.value.length >= len) {
      //     this.moveFocus(index + 1);
      // }
    },

    findIndex(target) {
      return [].findIndex.call(this.elements, (e) => e === target);
    },

    moveFocus(index) {
      console.log(index);
      console.log(document.getElementsByClassName("input-items")[index]);
      let text = document.getElementsByClassName("input-items")[index];
      // text.focus();
      // if (this.elements[index]) {
      //     this.elements[index].focus();
      // }
    },

    elements() {
      return document.getElementsByClassName("input-items");
    },

    searchItemTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i];
        }
      }
    },
    searchItemNextTableData(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          var n = i + 1;
          if (n >= myArray.length) {
            return myArray[i];
          } else {
            return myArray[n];
          }
        }
      }
    },
    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      this.isLoading = true;
      const toast = useToast();
      ApiService.delete(`crmv2/order/cart/admin/${this.companyId}/${item._id}`)
        .then((res) => {
          this.isLoading = false;
          toast.success("Hapus Berhasil");
          this.getData(this.paging.size, this.paging.page);
        })
        .catch((error) => {
          this.isLoading = false;
          toast.error("Gagal Hapus");
        });
    },

    setID(val) {
      return "textfield_" + val;
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      if (this.companyId) {
        // companyId = this.companyId;
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cekStatusNFC(val) {
      let length = val.length;
      if (length > 20) {
        return "Belum";
      } else {
        return "Sudah";
      }
    },
  },
});
